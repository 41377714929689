<template>
  <Button class="fr" @click="contrastData">
    显示对比后的数据
    <Modal v-model="showContrast" class="contrastModel">
      <p v-for="(showColorData,ind) in contrastDataList" :key="ind" v-html="showColorData"></p>
    </Modal>
  </Button>
</template>

<script>
import { getContrastData } from "../../../api/hbService";
export default {
  props: {
    hb: {
      required: true
    }  
  },
  data() {
    return {
      showContrast: false,
      contrastDataList: []
    };
  },
  methods: {
    contrastData() {
      this.showContrast = true;
      getContrastData(this.hb, this.currentIndex).then(res => {
        this.contrastDataList = res;
      });
    }
  }
};
</script>

<style scoped>
.contrastModel ::v-deep .ivu-modal-content {
  background-color: #000;
  color: white;
}

.contrastModel ::v-deep .ivu-modal-footer {
  border-top: 1px solid #333;
}
</style>